<template>
  <div id="mainPage" :style="backgroundImg">
    <div class="topBC">
      <!--顶部背景图片-->
      <img src="../static/images/logo_memberShip.png" width="110px" height="45px"/>
    </div>
    <div class="title">
      <span>入会测评预约</span>
      <img src="../static/images/logo_goAllOutMan.png" width="69px" height="86px"/>
      <em class="button" @click="onGoNewPage('testMemberNavi')">主页</em>
    </div>
    <!--预约内容区域-->
    <div class="context">
      <!--身体数据区域-->
      <div class="bodyInfoArea" style="margin-top: 85px">
        <div class="inputLine">
          <span class="leftTitle">姓名</span>
          <input class="middleInput" type="text" placeholder="请填写孩子姓名" v-model="form.name">
        </div>
        <div class="inputLine">
          <span class="leftTitle">年龄</span>
          <mu-select class="datePicker" v-model="form.age" style="margin-left: 7px">
            <mu-option v-for="option in form.ageOptions" :key="option.value" :label="option.label"
                       :value="option.value"></mu-option>
          </mu-select>
          <!--          <date-picker class="datePicker" v-model="form.birthday" value-type="month" @change="confirmDay"/>-->
        </div>
        <div class="inputLine">
          <span class="leftTitle">身高</span>
          <input class="middleInput" type="number" placeholder="请输入身高"
                 oninput="if(value.length > 5)value = value.slice(0, 5)" v-model="form.height">
          <em>CM</em>
        </div>
        <div class="inputLine">
          <span class="leftTitle">体重</span>
          <input class="middleInput" type="number" placeholder="请输入体重"
                 oninput="if(value.length > 5)value = value.slice(0, 5)" v-model="form.weight">
          <em>KG</em>
        </div>
        <div class="inputLine">
          <span class="leftTitle">性别</span>
          <div class="sexDiv">
            <input v-model="form.sex" type="radio" id="radio_man" checked value="1">
            <label for="radio_man" style="color: #FFFFFF">男</label>
          </div>
          <div class="sexDiv">
            <input v-model="form.sex" type="radio" id="radio_woman" value="2">
            <label for="radio_woman" style="color: #FFFFFF">女</label>
          </div>

        </div>
      </div>

      <div class="bodyInfoArea">
        <div class="inputLine">
          <span class="leftTitle4">训练场馆</span>
          <mu-select class="datePicker" v-model="form.testShopId" @change="getTimeItemOptions">
            <mu-option v-for="option in form.shopOptions" :key="option.value" :label="option.label"
                       :value="option.value"></mu-option>
          </mu-select>
        </div>
        <div class="inputLine">
          <span class="leftTitle4">预约时间</span>
          <mu-select class="datePicker" v-model="form.testTimeId">
            <mu-option v-for="option in form.timeOptions" :key="option.value" :label="option.label"
                       :value="option.value"></mu-option>
          </mu-select>
        </div>
        <div class="inputLine">
          <span class="leftTitle4">项目</span>
          <mu-select class="datePicker" v-model="form.testItemId" style="">
            <mu-option v-for="option in form.testOptions" :key="option.value" :label="option.label"
                       :value="option.value" style="!important color:white"/>
          </mu-select>
        </div>
        <div class="inputLine">
          <span class="leftTitle4">手机号码</span>
          <input class="middleInput" type="number" oninput="if(value.length > 11)value = value.slice(0, 11)"
                 placeholder="请输入手机号" v-model="form.phone" style="margin-left: -20px">
        </div>
        <div class="inputLine">
          <span class="leftTitle4">图形验证</span>
          <input class="middleInput" type="number" oninput="if(value.length > 4)value = value.slice(0, 4)"
                 placeholder="请输入数字" v-model="form.picValidCode">
          <img id="imgValidCode" class="validCode" :src="valImgSrc" :title="InvisibilityGiveitatry"
               @click="getValImgSrc">
        </div>
        <div class="inputLine">
          <span class="leftTitle4">短信验证</span>
          <input class="middleInput" type="text" placeholder="请输入短信" v-model="form.smsValidCode">
          <span class="validButton" :disabled="getValidSmsState" @click="getValidCode()">{{ btnText }}</span>
        </div>
      </div>

      <div class="bottom" @click="submitTest()">
        提交预约
      </div>
    </div>

    <alert ref="alertPart"></alert>
  </div>
</template>

<script>
import {
  CanOrderDateQuery,
  FTGroupTypeIsOrderOneQuery,
  GenVerifyPic,
  MyOrderFitnessTestCancel,
  NotUserGetPhoneVFCode,
  OrderFitnessTestAdd,
  OrderShopQuery,
  ShopQueryByUnLoginLoalLoutUser,
} from '../api/getApiRes.js'

import bottomTab from '../components/bottomTab'
import alert from '../components/alert'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/zh-cn';

let qs = require('qs');

export default {
  data() {
    return {
      dateTexttimer: '',//页面定时器
      btnText: '获取短信',
      getValidSmsState: false,//
      InvisibilityGiveitatry: "无效?再点一下试试吧!",
      valImgSrc: '',
      backgroundImg: '',
      form: {
        name: '',
        age: 5,
        height: '',
        weight: '',
        testShopId: '',
        testTimeId: '',
        testItemId: '',
        ftgId: '',
        phone: '',
        imgValidCode: '',
        picValidCode: '',
        smsValidCode: '',
        sex: '',
        ageOptions: this.getAge(),
        shopOptions: [],
        timeOptions: [],
        testOptions: [],
      },
      list: [],
    }
  },
  mounted() {
    this.getValImgSrc();
    this.getShopList();
    if (localStorage.phone) {
      this.form.phone = localStorage.phone;
    }
  },
  destroyed() {
  },
  watch: {
    $route(to) {
      if (to.name == 'testMembership') {
        this.getValImgSrc();
        this.getShopList();
        if (localStorage.phone) {
          this.form.phone = localStorage.phone;
        }
      }
    },
    'active'() {
      this.getList();
    }
  },
  methods: {
    alertInfo(info) {
      this.$refs.alertPart.openSimpleDialog(info);
    },
    confirmDay(car) {
      console.log(globaltime2StringNoMin(car));
    },
    getAge() {
      let ageOptions = [];
      for (let i = 5; i < 16; i++) {
        ageOptions.push({label: i + '岁', value: i});
      }
      return ageOptions;
    },
    getShopList() {
      let that = this;
      let param = {};
      let postdata = qs.stringify(param);
      ShopQueryByUnLoginLoalLoutUser(postdata).then(res => {
        let json = res;
        if (json.Code == 0) {
          that.form.shopOptions = jsonToOptionBySimpleShop(json.Rs);
          if (that.form.shopOptions.length > 0) {
            that.form.testShopId = that.form.shopOptions[0].value;
            that.getTimeItemOptions();
          }
        }
      })
    },
    getTimeItemOptions() {
      let that = this;
      that.getShopTimeList();//获取店铺可预约时间列表
      that.getShopItemList();//获取店铺可预约项目列表
    },
    //获取店铺可预约时间列表
    getShopTimeList() {
      let that = this;
      let param = {
        shopId: that.form.testShopId
      };
      let postdata = qs.stringify(param);
      CanOrderDateQuery(postdata).then(res => {
        let json = res;
        if (json.Code == 0) {
          if (json.Rs.length > 0) {
            that.form.timeOptions = jsonToOptionByShopTimeList(json.Rs);
            that.form.testTimeId = that.form.timeOptions[0].key;
          } else {
            if (this.pageType == 1) {
              that.Toast('该店预约已满');
            }
            that.form.timeOptions = [];
            that.form.testTimeId = '';
          }
        }
      })
    },
    //获取店铺可预约项目列表
    getShopItemList() {
      let that = this;
      let param = {
        shopId: that.form.testShopId,
      };
      let postdata = qs.stringify(param);
      FTGroupTypeIsOrderOneQuery(postdata).then(res => {
        let json = res;
        if (json.Code == 0) {
          if (json.Rs.length > 0) {
            that.form.testOptions = jsonToOptionByShopItemList(json.Rs);
            that.form.testItemId = that.form.testOptions[0].key;
          }
        }
      })
    },
    // 点击验证码切换
    getValImgSrc() {
      let that = this;
      let param = {
        token: localStorage.token,
        width: 100,
        height: 30,
        noiseCount: 1,
        length: 4,
        source: 123456789,
      };
      let postdata = qs.stringify(param);
      GenVerifyPic(postdata).then(res => {
        let json = res;
        that.valImgSrc = json.Pic;
        that.valImgId = json.Id;
      })
    },
    //获取短信验证码
    getValidCode() {
      let that = this;
      if(that.getValidSmsState){
        return;
      }
      let phone = that.form.phone;
      let uservalid = that.form.picValidCode;
      if (!phone) {
        that.Toast('手机号不能为空');
        return false
      }
      if (phone.length != 11) {
        that.Toast('手机号只能是11位');
        return false
      }
      if (!globalCheckPhone(phone)) {
        that.Toast('手机号格式不正确');
        return false
      }
      if (!uservalid) {
        that.Toast('图形验证码不能为空');
        return false
      }
      if (uservalid.length != 4) {
        that.Toast('图形验证码只能是4位');
        return false
      }
      let param = {
        token: localStorage.token,
        phone: phone,
        codeType: 2,
        picId: this.valImgId,
        picCode: uservalid,
        oType: 1
      };
      let postdata = qs.stringify(param);
      NotUserGetPhoneVFCode(postdata).then(res => {
        let json = res;
        if (json.Code == 0) {
          that.Toast('短信验证码已发送', 'success');
          that.calcTime();
        } else {
          that.Toast(json.Memo);
          if (json.Code == 1003) {
            this.getValImgSrc();
            that.form.picValidCode = '';
          }
        }
      })
    },
    calcTime() {
      // 短信倒数计时
      let countdown = 60;
      let that = this;
      //设置button效果，开始计时
      that.getValidSmsState = true;
      that.btnText = countdown + "S"
      //启动计时器，1秒执行一次
      let timer = setInterval(function () {
        if (countdown == 0) {
          clearInterval(timer);//停止计时器
          that.getValidSmsState = false;
          that.btnText = "重新发送"
        } else {
          countdown--;
          that.btnText = countdown + "S"
        }
      }, 1000);
    },
    submitTest() {
      let that = this;
      if (!that.form.name) {
        that.Toast('姓名不能为空');
        return false
      }
      if (!that.form.age) {
        that.Toast('请填写年龄');
        return false
      }
      if (parseInt(that.form.height) > 200 || parseInt(that.form.height) < 90) {
        that.Toast('请输入正确的身高');
        return false
      }
      if (parseInt(that.form.weight) > 120 || parseInt(that.form.weight) < 10) {
        that.Toast('请输入正确的体重');
        return false
      }
      if (!that.form.sex) {
        that.Toast('请选择性别');
        return false
      }
      if (!that.form.testShopId) {
        that.Toast('请选择商家');
        return false
      }
      if (!that.form.testTimeId) {
        that.Toast('请选择预约时间');
        return false
      }
      if (!that.form.testItemId) {
        that.Toast('请选择预约项目');
        return false
      }
      if (that.form.phone.length != 11) {
        that.Toast('请输入正确的手机号');
        return false
      }
      if (!that.form.phone) {
        that.Toast('手机不能为空');
        return false
      }
      if (!that.form.smsValidCode) {
        that.Toast('短信验证码不能为空');
        return false
      }

      let postData = {
        codId: that.form.testTimeId,
        figId: that.form.testItemId,
        phone: that.form.phone,
        code: that.form.smsValidCode,
        name: that.form.name,
        sex: that.form.sex,
        age: that.form.age,
        height: that.form.height,
        weight: that.form.weight,
        openId: localStorage.openId ? localStorage.openId : ''
      };
      OrderFitnessTestAdd(qs.stringify(postData)).then(res => {
        let json = res;
        if (json.Code == 0) {
          let that = this;
          localStorage.phone = that.form.phone;
          this.$router.push({
            path: 'testMembershipAddress', query: {
              oftId:json.OftId,
              phone:that.form.phone,
              orderTime:json.CanOrderDate,
              orderShop:json.ShopName,
              orderAddress:json.Addr,
              pageType:2
            }
          });
        } else {
          that.alertInfo(json.Memo);
          that.getShopItemList();
        }
      })
    },
    onGoNewPage(path) {
      this.$router.push({path: '/' + path});
    },
    goPage(pages) {
      this.$router.push({path: pages});
    },
  },
  beforeDestroy() {
    clearInterval(this.dateTexttimer);
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      //因为当钩子执行前，组件实例还没被创建
      // vm 就是当前组件的实例相当于上面的 this，所以在 next 方法里你就可以把 vm 当 this 来用了。
      if (to.name == '') {
        vm.getList();
      }
    });
  },
  components: {
    bottomTab, alert, DatePicker
  }
}
</script>

<style scoped>
#mainPage {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: block;
  margin: 0 auto;
  padding-bottom: 50px;
  background: url('../static/images/bc_memberTest.png') no-repeat center;
  overflow-y: scroll;
}

.topBC {
  height: 80px;
  width: 100%;
  text-align: center;
}

.topBC img {
  margin-top: 21px;
}

.mainPage .title {
  height: 35px;
  width: 100%;
  margin-top: 10px;
}

.title span {
  display: block;
  font-size: 28px;
  text-align: center;
  color: white;
}

.title img {
  float: right;
  margin-top: -50px;
  margin-right: 25px;
}

.title .button {
  width: 50px;
  font-size: 14px;
  height: 25px;
  background: rgba(174, 161, 161, 0.68);
  display: block;
  text-align: center;
  line-height: 25px;
  color: white;
  margin-left: 5%;
  margin-top: -100px;
  border-radius: 3px;
}


.bodyInfoArea {
  width: 90%;
  margin-left: 5%;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(10px);
  border-radius: 5px;
  margin-top: 15px;
  padding-bottom: 20px;
}

.bodyInfoArea .inputLine {
  height: 50px;
  width: 80%;
  margin-top: 5px;
  margin-left: 10%;
  display: flex;
  border-bottom: #ebebeb16 1px solid;
}

.inputLine .leftTitle {
  width: 50px;
  display: block;
  font-size: 14px;
  font-weight: bold;
  color: white;
  text-align: left;
  line-height: 60px;
}

.inputLine .leftTitle4 {
  width: 95px;
  display: block;
  font-size: 14px;
  color: white;
  font-weight: bold;
  text-align: left;
  line-height: 60px;
}

.inputLine .sexDiv {
  width: 60px;
  line-height: 60px;
  margin-left: 7px;
}

.inputLine .sexDiv label {
  margin-left: 10px;

}

.inputLine .middleInput {
  width: 45%;
  position: relative;
  margin-top: 15px;
  font-size: 14px;
  color: white;
  text-align: left;
  outline: medium;
  height: 29px;
  text-indent: 10px;
  margin-left: -5px;
  background: none;
  border: none;
}

::v-deep .mu-select-input {
  color: white;
}

::v-deep .mx-input {
  color: white;
  background-color: rgba(66, 66, 66, 0.6);
  border: none;

}

.inputLine .validCode {
  width: 85px;
  height: 25px;
  margin-top: 15px;
  margin-left: 1%;
  text-indent: 10px;
  border: #E3E3E3 1px solid;
}

.inputLine .datePicker {
  width: 166px;
  margin-top: 12px;
  color: white;
  font-size: 14px;
  border: none;
  margin-left: -5px;
  height: 29px;
  /*text-indent: 10px;*/
  /*border:#E3E3E3 1px solid;*/
}

.inputLine em {
  color: white;
  width: 20px;
  line-height: 58px;
  margin-left: 10px;
}

.inputLine .validButton {
  width: 95px;
  height: 25px;
  line-height: 25px;
  font-size: 14px;
  margin-top: 17px;
  border-radius: 2px;
  color: #ffffff;
  background: #FF6E14;
  text-align: center;
}

.context {
  width: 95%;
  margin-left: 2.5%;
  padding-top: 1px;
  margin-top: 5px;
  border-radius: 10px;
  padding-bottom: 30px;
}

.context .bottom {
  height: 45px;
  background: #E75296;
  width: 75%;
  font-size: 18px;
  color: white;
  border-radius: 23px;
  text-align: center;
  line-height: 45px;
  margin: 25px auto 0;
}

/*预约结果*/
.title span {
  display: block;
  font-size: 36px;
  color: white;
  text-align: center;
}

.context .appointStatus {
  width: 90%;
  background: #FFFFFF;
  margin: 0 auto;
  padding-bottom: 20px;
  border-radius: 15px;
}

.appointStatus .contentTitle {
  height: 70px;
  line-height: 70px;
  font-size: 27px;
  color: #37CB00;
  margin-top: 35px;
  margin-bottom: 5px;
  text-align: center;
  width: 100%;
}

.appointStatus .resultLine em {
  font-size: 14px;
  margin-left: 40px;
}

.appointStatus .contentLine {
  height: 30px;
  line-height: 30px;
}

.appointStatus .contentLine span {
  font-size: 14px;
  margin-left: 39px;
}

.appointStatus .contentLine em {
  font-size: 14px;
  margin-left: 12px;
}

.appointStatus .contentTime {
  margin-top: 15px;
  height: 30px;
  line-height: 30px;
}

.appointStatus .contentTime span {
  font-size: 14px;
  margin-left: 39px;
}

.appointStatus .contentTime em {
  font-size: 14px;
  margin-left: 5px;
}

.appointStatus .cancelButton {
  float: right;
  margin-right: 12%;
  font-size: 14px;
  color: #FF5800;
}

.appointStatus .disableButton {
  font-size: 14px;
  color: #CECECE;
}

.context .testNeedsKnown {
  width: 90%;
  background: #FFFFFF;
  margin: 20px auto;
  border-radius: 15px;
  color: #3B3B3B;
}

.testNeedsKnown img {
  width: 100%;
}

.testNeedsKnown span {
  font-size: 16px;
  text-align: center;
  padding-bottom: 40px;
  display: block;
  color: #4A4A4A;
  margin: 0 auto;
}


::-webkit-input-placeholder {
  color: #ffb8d9;
}

/*响应式调整*/
@media only screen and (max-width: 320px) {
}

@media (min-width: 321px) and (max-width: 344px) {
}

@media (min-width: 345px) and (max-width: 360px) {
}

@media (min-width: 361px) and (max-width: 375px) {
}

@media (min-width: 376px) and (max-width: 396px) {
}

@media (min-width: 397px) and (max-width: 414px) {
}

@media (min-width: 415px) and (max-width: 480px) {
}

@media (min-width: 481px) and (max-width: 640px) {
}
</style>
